<template>
  <div class="view-home">
    <div v-if="pageLoading" class="text-center p-5">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
    </div>
    <div v-else>
      <div class="row mb-4">
        <div class="col-lg-12">
          <exam-available />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <card>
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <div class="d-flex justify-content-center">
                  <radial-progress-bar
                    class="mr-2"
                    :diameter="130"
                    :completed-steps="
                      $user.user &&
                      $user.user.progress &&
                      $user.user.progress.lessons
                        ? $user.user.progress.lessons.length
                        : 0
                    "
                    :total-steps="lessonsCount || 0"
                    startColor="#74AFFC"
                    stopColor="#74AFFC"
                    innerStrokeColor="#E5E5E5"
                    :strokeWidth="4"
                    :innerStrokeWidth="5"
                  >
                    {{ `${lessonAverage}%` }}
                  </radial-progress-bar>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="text-left pt-3">
                  <h2 class="font-weight-bold d-none d-lg-block">
                    Você já completou:
                  </h2>
                  <h3 class="font-weight-bold d-lg-none">
                    Você já completou:
                  </h3>
                  <h5 class="text-secondary">
                    {{
                      `${
                        $user.user &&
                        $user.user.progress &&
                        $user.user.progress.lessons
                          ? $user.user.progress.lessons.length
                          : 0
                      } de ${lessonsCount} aulas`
                    }}
                  </h5>
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="col-lg-6">
          <card>
            <div class="py-2">
              <div class="row">
                <div class="col-lg-6 mb-2 mb-lg-0">
                  <div class="text-center mb-3">
                    <h1 class="font-weight-bold">{{ modules.length }}</h1>
                    <h3 class="text-secondary">Módulos</h3>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="text-center">
                    <h1 class="font-weight-bold">{{ lessonsCount }}</h1>
                    <h3 class="text-secondary">Aulas</h3>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <card>
            <div class="mb-4 font-weight-bold">
              Módulos do curso
            </div>
            <!-- desktop area  start-->
            <data-table
              :columns="['#', 'Nome', 'Situação']"
              :data="modules"
              :slim="true"
              :expandOnHover="false"
              :firstColumnFr="0.1"
              :lastColumnFr="1"
            >
              <template #default="{ item }">
                <small class="font-weight-bold d-flex align-items-center pl-2">
                  {{ item.index }}
                </small>
                <small class="font-weight-bold d-flex align-items-center pl-2">
                  {{ item.name }}
                </small>
                <small class="font-weight-bold d-flex align-items-center pl-2">
                  {{ item.released ? "Aulas disponíveis" : "Não iniciado" }}
                </small>
              </template>
            </data-table>
            <!-- desktop area end-->
          </card>
        </div>
        <div class="col-lg-6">
          <card>
            <div class="mb-4 font-weight-bold">
              Últimas aulas adicionadas
            </div>
            <!-- desktop area  start-->
            <data-table
              :columns="['#', 'Título', 'Ação']"
              :data="lastLessons"
              :slim="true"
              :expandOnHover="false"
              :firstColumnFr="0.1"
              :lastColumnFr="0.3"
            >
              <template #default="{ item }">
                <small class="font-weight-bold d-flex align-items-center pl-2">
                  {{ item.index }}
                </small>
                <small class="font-weight-bold d-flex align-items-center pl-2">
                  {{ item.title }}
                </small>
                <button
                  class="btn btn-outline-primary btn-see"
                  @click="seeLesson(item)"
                >
                  Assistir
                </button>
              </template>
            </data-table>
            <!-- desktop area end-->
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentMixin from "@/mixins/content.js";
import { findLastIndex } from "@/functions/helpers.js";
import { mapState } from "vuex";
export default {
  mixins: [ContentMixin],
  mounted() {
    this.initData();
  },
  computed: {
    ...mapState("content", ["modules", "lessons", "lessonsCount", "lesson"]),
    progressCount() {
      if (!this.$user.user || !this.$user.user.progress) {
        return 0;
      }

      const progress = this.$user.user.progress;
      if (progress && progress.lessons) {
        return progress.lessons.length;
      }
      return 0;
    },
    lastLessons() {
      if (!this.modules.length || !Object.entries(this.lessons).length)
        return [];

      const lastModuleIndex = findLastIndex(
        this.modules,
        (v, i) => v.released && this.lessons[v.id]
      );
      if (lastModuleIndex) {
        return this.lessons[this.modules[lastModuleIndex].id];
      }

      return [];
    },
    lessonAverage() {
      if (!this.lessonsCount) return 0;

      const average = (this.progressCount * 100) / this.lessonsCount;
      return average.toFixed(2);
    }
  },
  methods: {
    seeLesson(item) {
      this.$store.commit("content/setLesson", item);
      this.$router.push({
        name: "content"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-home {
  .centralize {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
