var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-home"},[(_vm.pageLoading)?_c('div',{staticClass:"text-center p-5"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary"}})],1):_c('div',[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-lg-12"},[_c('exam-available')],1)]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-lg-6 mb-4 mb-lg-0"},[_c('card',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('radial-progress-bar',{staticClass:"mr-2",attrs:{"diameter":130,"completed-steps":_vm.$user.user &&
                    _vm.$user.user.progress &&
                    _vm.$user.user.progress.lessons
                      ? _vm.$user.user.progress.lessons.length
                      : 0,"total-steps":_vm.lessonsCount || 0,"startColor":"#74AFFC","stopColor":"#74AFFC","innerStrokeColor":"#E5E5E5","strokeWidth":4,"innerStrokeWidth":5}},[_vm._v(" "+_vm._s((_vm.lessonAverage + "%"))+" ")])],1)]),_c('div',{staticClass:"col-lg-7"},[_c('div',{staticClass:"text-left pt-3"},[_c('h2',{staticClass:"font-weight-bold d-none d-lg-block"},[_vm._v(" Você já completou: ")]),_c('h3',{staticClass:"font-weight-bold d-lg-none"},[_vm._v(" Você já completou: ")]),_c('h5',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(((_vm.$user.user && _vm.$user.user.progress && _vm.$user.user.progress.lessons ? _vm.$user.user.progress.lessons.length : 0) + " de " + _vm.lessonsCount + " aulas"))+" ")])])])])])],1),_c('div',{staticClass:"col-lg-6"},[_c('card',[_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 mb-2 mb-lg-0"},[_c('div',{staticClass:"text-center mb-3"},[_c('h1',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.modules.length))]),_c('h3',{staticClass:"text-secondary"},[_vm._v("Módulos")])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.lessonsCount))]),_c('h3',{staticClass:"text-secondary"},[_vm._v("Aulas")])])])])])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 mb-4 mb-lg-0"},[_c('card',[_c('div',{staticClass:"mb-4 font-weight-bold"},[_vm._v(" Módulos do curso ")]),_c('data-table',{attrs:{"columns":['#', 'Nome', 'Situação'],"data":_vm.modules,"slim":true,"expandOnHover":false,"firstColumnFr":0.1,"lastColumnFr":1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var item = ref.item;
return [_c('small',{staticClass:"font-weight-bold d-flex align-items-center pl-2"},[_vm._v(" "+_vm._s(item.index)+" ")]),_c('small',{staticClass:"font-weight-bold d-flex align-items-center pl-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',{staticClass:"font-weight-bold d-flex align-items-center pl-2"},[_vm._v(" "+_vm._s(item.released ? "Aulas disponíveis" : "Não iniciado")+" ")])]}}])})],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('card',[_c('div',{staticClass:"mb-4 font-weight-bold"},[_vm._v(" Últimas aulas adicionadas ")]),_c('data-table',{attrs:{"columns":['#', 'Título', 'Ação'],"data":_vm.lastLessons,"slim":true,"expandOnHover":false,"firstColumnFr":0.1,"lastColumnFr":0.3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var item = ref.item;
return [_c('small',{staticClass:"font-weight-bold d-flex align-items-center pl-2"},[_vm._v(" "+_vm._s(item.index)+" ")]),_c('small',{staticClass:"font-weight-bold d-flex align-items-center pl-2"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('button',{staticClass:"btn btn-outline-primary btn-see",on:{"click":function($event){return _vm.seeLesson(item)}}},[_vm._v(" Assistir ")])]}}])})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }